/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import UseTermPanel from './components/UseTermPanel.vue';

const CONVERSATION_PERMISSIONS = [
  'administrator',
  'agent',
  'conversation_manage',
  'conversation_unassigned_manage',
  'conversation_participating_manage',
];

export const routes = [
  {
    path: frontendURL('accounts/:accountId/term'),
    component: UseTermPanel,
    name: 'Term',
    meta: {
      permissions: ['administrator', 'agent', ...CONVERSATION_PERMISSIONS],
    },
  },
];
