<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAlert } from 'dashboard/composables';
import term from './generateTerm.vue';
import termVersion from './termVersion.js';
import axios from 'axios';

const store = useStore();
const router = useRouter();

const selected = ref(false);
const { t } = useI18n();
const currentUser = computed(() => store.getters.getCurrentUser);

const currentUserDetails = computed(() => {
  const { email, name, custom_attributes } = currentUser.value;
  return { email, name, cpf: custom_attributes?.cpf || '' };
});

const hasAcceptedTerms = currentUser.value?.custom_attributes?.accepted_terms;
const acceptedTerms = hasAcceptedTerms ? JSON.parse(hasAcceptedTerms) : [];

const acceptTerm = async () => {
  try {
    const { data } = await axios('https://api.ipify.org?format=json');
    const userIp = data.ip;

    const newAcceptedTerms = [
      ...acceptedTerms,
      {
        term_version: termVersion,
        accepted_at: new Date().toISOString(),
        device_info: navigator.userAgent,
        ip_address: userIp,
      },
    ];

    await store.dispatch('updateUserCustomAttributes', {
      accepted_terms: JSON.stringify(newAcceptedTerms),
    });

    useAlert(t('TERM_PAGE.ACCEPT_TERM_SUCCESS'));
    router.replace({ name: 'home' });
  } catch (error) {
    useAlert(t('TERM_PAGE.ACCEPT_TERM_ERROR'));
  }
};
</script>

<template>
  <div class="modal-mask py-10 flex flex-col">
    <h1 v-if="acceptedTerms.length" class="font-extrabold text-3xl">
      {{ t('TERM_PAGE.UPDATE_HEADER') }}
    </h1>
    <h1 v-else class="font-extrabold text-3xl">
      {{ t('TERM_PAGE.NEW_HEADER') }}
    </h1>
    <div
      class="overflow-scroll h-full mx-auto w-5/6 my-6 bg-white text-n-black p-6 rounded-md"
    >
      <term
        :name="currentUserDetails.name"
        :email="currentUserDetails.email"
        :cpf="currentUserDetails.cpf"
      />
      <div class="flex flex-col justify-center">
        <div class="flex flex-row gap-2 justify-center">
          <input v-model="selected" class="checkbox" type="checkbox" />
          <p class="mb-0">{{ t('TERM_PAGE.READ_CONFIRMATION') }}</p>
        </div>
        <div class="flex justify-center mt-3">
          <woot-button :disabled="!selected" @click="acceptTerm">
            {{ t('TERM_PAGE.ACCEPT_TERM') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>
